import { OktaAuth } from '@okta/okta-auth-js';
import Util from 'util/Util';
import config from 'config/config.json';
import _ from 'underscore';

export default function(options) {
  var authParams = _.extend({
    clientId: options.clientId,
    redirectUri: options.redirectUri,
    state: options.state,
    scopes: options.scopes,
    transformErrorXHR: Util.transformErrorXHR,
  }, options.authParams);

  var impAuthParams = _.clone(authParams);
  impAuthParams.issuer = options.impUrl + '/oauth2/default';
  var impAuthClient = options.impAuthClient ? options.impAuthClient : new OktaAuth(impAuthParams);
  if (!impAuthClient._oktaUserAgent) {
    // TODO: this block handles OKTA UA for passed in impAuthClient, error should be thrown in the next major version
    // For now, do nothing here to preserve the current behavior
    // JIRA: https://oktainc.atlassian.net/browse/OKTA-433378
    // throw new Errors.ConfigError('The passed in impAuthClient should be version 5.4.0 or above.');
  } else {
    impAuthClient._oktaUserAgent.addEnvironment(`okta-signin-widget-${config.version}`);
  }

  // Set default headers object for widget access
  // TOOD: remove when auth-js includes https://oktainc.atlassian.net/browse/OKTA-435081
  impAuthClient.options.headers = impAuthClient.options.headers || {};

  return impAuthClient;
}
